import React, { useEffect, useState } from "react";
import NotFound from "@/views/error/NotFound";
import http, { httpPublic } from "@/utils/http";
import endpoints from "@/constants/endpoints";
import debug from "@/utils/debug";
import PageLoader from "@/components/widgets/PageLoader";
import RestaurantRoutes from "@/router/RestaurantRoutes";
import { useParams } from "react-router";
import { tenantInitialState, TenantContext, useTenant } from "@/router/TenantGateway";
import buildRoot from "@/router/utils";
import { isLight } from "@/utils/color";
import { injectManifest } from "../utils/injectManifest";

/**
 * [Multi-tenancy router FOR RESTAURANTS]
 *
 * Handles the root-level splitting for multi-tenancy purpose.
 * Injects the tenantId (namespace) as a top level prop
 */
const RestaurantGateway = () => {
  // local states
  const [options, setOptions] = useState(tenantInitialState.options); // this will override higher level (tenant) options
  const [restaurantIdVerified, setRestaurantIdVerified] = useState(false);
  const [loading, setLoading] = useState(false);

  const { tenantId, clientId } = useTenant();

  // route params
  const { restaurantId } = useParams();
  debug("RestaurantGateway rendered", "restaurantId", restaurantId);

  useEffect(() => {
    setLoading(true); // need to reload

    if (restaurantIdVerified) {
      setLoading(false);
      return;
    }

    const loadData = async () => {
      try {
        const [
          {
            data: { restaurants },
          },
          { data: dataOptions },
          { data: manifestData },
        ] = await Promise.all([
          (await httpPublic(tenantId)).get(endpoints(tenantId, restaurantId).options.restaurants), // verify restaurantId
          await (await http(tenantId)).get(endpoints(tenantId, restaurantId).options.main), // get options
          await (await http(tenantId)).get(endpoints(tenantId, restaurantId).options.manifest),
        ]);

        if (manifestData != null) injectManifest(manifestData);

        const restaurant = restaurants[restaurantId];

        if (restaurant) setRestaurantIdVerified(true);
        if (dataOptions) {
          const { logo = "", featureImage = "", highlightBtnColor = {}, headerColor = {} } = dataOptions;
          setOptions({
            name: restaurant.name,
            logo,
            featureImage,
            highlightBtnColor,
            headerColor,
          });
        }
      } catch (e) {
        debug(e);
      }
      setLoading(false);
    };
    loadData().then();
  }, [tenantId, restaurantId]);

  if (loading) return <PageLoader />;

  // !match means no rId is passed
  if (!restaurantId || !restaurantIdVerified) {
    debug("No restaurantId passed to route, or failed to verify restaurantId");
    return <NotFound />;
  }
  const theme = {
    headerColor: `rgb(${options.headerColor.r},${options.headerColor.g},${options.headerColor.b})`,
    primaryText: isLight(`rgb(${options.highlightBtnColor.r},${options.highlightBtnColor.g},${options.highlightBtnColor.b})`) ? "black" : "white",
    btnColor: `rgb(${options.highlightBtnColor.r},${options.highlightBtnColor.g},${options.highlightBtnColor.b})`,
  };

  // successfully matched
  return (
    <TenantContext.Provider
      value={{
        tenantId,
        clientId,
        restaurantId,
        options,
        loading,
        root: buildRoot(tenantId, restaurantId),
        isTenant: false,
        isRestaurant: true,
        theme,
      }}
    >
      <RestaurantRoutes />
    </TenantContext.Provider>
  );
};

export default RestaurantGateway;
